<template>
  <section class="add-tests-view">
    <div class="select-test-panel pl-20 coriell-test-block">
      <div class="coriell-test-profiles">
        <h4 class="title">Select Panels</h4>
        <el-select
          v-model="selectedWoundPanels"
          multiple
          placeholder="Select"
          @change="handleWoundTests"
          @remove-tag="handleRemovedTests"
          value-key="code"
        >
          <el-option
            v-for="(item, index) in WoundPanels"
            :key="index"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <ul class="add-tests-selection">
      <li
        class="each-test"
        v-for="(category, index) of getCaseCategoryData('WOUND')"
        :key="index"
      >
        <el-collapse v-model="activeNameCollapse" accordion>
          <el-collapse-item :name="index">
            <template #title>{{ category.category }}</template>
            <el-checkbox
              class="test-category"
              disabled
              :model-value="
                isCheckAll(category.case_type_code, category.category_code)
              "
              @change="
                checkAll(
                  $event,
                  category.case_type_code,
                  category.category_code
                )
              "
              >All</el-checkbox
            >
            <el-checkbox-group v-model="testsData">
              <div
                v-for="(item, index) of getCategoryWiseTestsData(
                  category.case_type_code,
                  category.category_code
                )"
                :key="index"
              >
                <div class="each-check-box">
                  <el-checkbox disabled :label="item._id" :value="item">{{
                    item.name
                  }}</el-checkbox>
                </div>
              </div>
            </el-checkbox-group>
          </el-collapse-item>
        </el-collapse>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import AppHelper from "@/mixins/AppHelper";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";

export default {
  props: ["panelTestsData", "searchString", "customProfileTest"],
  mixins: [AppHelper, UserPermissionsHelper],
  data() {
    return {
      activeNameCollapse: "",
      WoundPanels: [],
      selectedWoundPanels: [],
      panelsTestsData: "",
      // testsData: [],
      selectedTestProfile: [],
      TestProfiles: [],
      userType: "",
    };
  },
  computed: {
    ...mapGetters("labTests", ["getAllLabTests"]),
    ...mapGetters("cases", ["getCorriellTestPanels"]),
    ...mapGetters("auth", ["getAuthenticatedUser","getUserPrimaryLabId"]),
    ...mapGetters("profile", ["getProfileData"]),
    ...mapGetters("testProfiles", ["getCaseTypeTestProfiles"]),

    checkLab() {
      if (
        this.getProfileData &&
        this.getProfileData.lab &&
        this.getProfileData.lab.lab_code === "DLW"
      ) {
        return true;
      }
      return false;
    },
    getTestsInformationObject() {
      const obj = {};
      if (this.getAllLabTests) {
        this.getAllLabTests.forEach((el) => {
          obj[el._id] = el;
        });
      }
      return obj;
    },
    selectedTestsDetails() {
      const data = [];
      if (this.panelsTestsData.selectedTestIds) {
        this.panelsTestsData.selectedTestIds.forEach((el) => {
          if (el && this.getTestsInformationObject[el]) {
            data.push(this.getTestsInformationObject[el]);
          }
        });
      }

      return data;
    },
    testsData() {
      if (
        this.panelsTestsData &&
        this.panelsTestsData.selectedTestIds &&
        this.panelsTestsData.selectedTestIds.length
      ) {
        return JSON.parse(JSON.stringify(this.panelsTestsData.selectedTestIds));
      }
      return [];
    },
  },
  methods: {
    getCategoryWiseTestsData(caseType, category) {
      let data = [];
      if (this.searchString) {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType &&
            el.category_code === category &&
            (el.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
              el.category
                .toLowerCase()
                .includes(this.searchString.toLowerCase()))
        );
      } else {
        data = this.getAllLabTests.filter(
          (el) =>
            el.case_type_code === caseType && el.category_code === category
        );
      }

      return this.sortByKey(data, "name");
    },
    getCaseCategoryData(caseType) {
      const data = this.getAllLabTests.filter(
        (el) =>
          el.case_type_code === caseType &&
          (!this.searchString ||
            el.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
            el.category.toLowerCase().includes(this.searchString.toLowerCase()))
      );

      let flags = [],
        output = [],
        outputWithFullDetails = [],
        l = data.length,
        i;
      for (i = 0; i < l; i++) {
        if (flags[data[i].category_code]) continue;
        flags[data[i].category_code] = true;
        output.push(data[i].category_code);
        outputWithFullDetails.push(data[i]);
      }
      return outputWithFullDetails;
    },
    checkAll(data, caseType, category) {
      let selectedData = this.getCategoryWiseTestsData(caseType, category).map(
        (item) => item._id
      );
      if (data) {
        for (let i = 0; i < selectedData.length; i++) {
          if (this.testsData.indexOf(selectedData[i]) == -1) {
            this.panelsTestsData.selectedTestIds.push(selectedData[i]);
          }
        }
      } else {
        for (let i = 0; i < selectedData.length; i++) {
          let index = this.testsData.indexOf(selectedData[i]);
          if (index > -1) {
            this.panelsTestsData.selectedTestIds.splice(index, 1);
          }
        }
      }
    },

    isCheckAll(caseType, category) {
      let selectedData = this.getCategoryWiseTestsData(caseType, category).map(
        (item) => item._id
      );

      return selectedData.every((item) => {
        return this.testsData.indexOf(item) !== -1;
      });
    },
    // selecting tests based on UTI coriell panels
    handleRemovedTests(testData) {
      let testPanel = testData.code;
      let selectedPanels = ["MH_W_BACTERIA", "MH_W_ABRESISTANCE"];
      if (testPanel.indexOf("MH_W_BACTERIA") > -1) {
        this.selectedWoundPanels = this.selectedWoundPanels.filter(
          (panel) => selectedPanels.indexOf(panel.code) == -1
        );

        this.handleWoundTests();
      }
      if (testPanel.indexOf("MH_W_ABRESISTANCE") > -1) {
        this.selectedWoundPanels = this.selectedWoundPanels.filter(
          (panel) => selectedPanels.indexOf(panel.code) == -1
        );

        this.handleWoundTests();
      }
    },
    handleTestProfiles() {
      if (this.selectedWoundPanels && this.selectedWoundPanels.length) {
        this.selectedWoundPanels = [];
      }
      this.$emit("test-profiles-selected", this.selectedTestProfile);
      if (this.selectedTestProfile && this.selectedTestProfile.length) {
        let retrievedLabProfiles = [];
        let selectedTests = [];

        for (const id of this.selectedTestProfile) {
          const matchingProfile = this.TestProfiles.find(
            (profile) => profile._id === id
          );

          if (matchingProfile) {
            retrievedLabProfiles.push(matchingProfile);
            selectedTests = selectedTests.concat(matchingProfile.lab_test_ids);
          }
        }

        // getting unique tests and removing non selected tests

        if (selectedTests.length) {
          this.removeAll();
          this.panelsTestsData.selectedTestIds = this.getUniquIds([
            ...this.panelsTestsData.selectedTestIds,
            ...selectedTests,
          ]);
        }
      } else {
        this.removeAll();
        this.panelsTestsData.selectedCoriellPanels =
          this.panelsTestsData.selectedCoriellPanels.filter(
            (x) => x.case_type != "WOUND"
          );
      }
    },
    handleWoundTests() {
      // if (this.selectedTestProfile && this.selectedTestProfile.length) {
      //   this.selectedTestProfile = [];
      // }
      if (this.checkConflictExist()) {
        // we have to remove recently added one
        this.selectedWoundPanels.pop();
        return false;
      }

      let woundIndex = this.panelsTestsData.selectedCoriellPanels.findIndex(
        (e) => e.case_type == "WOUND"
      );
      let testPanels = this.selectedWoundPanels.map((test) => test.code);

      if (
        testPanels.indexOf("MH_W_ABRESISTANCE") > -1 &&
        testPanels.indexOf("MH_W_BACTERIA") == -1
      ) {
        testPanels.push("MH_W_BACTERIA");
      }

      if (
        testPanels.indexOf("MH_W_ABRESISTANCE") == -1 &&
        testPanels.indexOf("MH_W_BACTERIA") > -1
      ) {
        testPanels.push("MH_W_ABRESISTANCE");
      }
      this.selectedWoundPanels = this.WoundPanels.filter(
        (panel) => testPanels.indexOf(panel.code) > -1
      );
      if (woundIndex > -1) {
        this.panelsTestsData.selectedCoriellPanels[woundIndex] = {
          case_type: "WOUND",
          test_panels: testPanels,
        };
      } else {
        this.panelsTestsData.selectedCoriellPanels.push({
          case_type: "WOUND",
          test_panels: testPanels,
        });
      }
      // getting unique tests and removing non selected tests
      let selectedTests = [].concat.apply(
        [],
        this.selectedWoundPanels.map((e) => e.tests)
      );
      if (selectedTests.length) {
        this.removeAll();
        this.panelsTestsData.selectedTestIds = this.getUniquIds([
          ...this.panelsTestsData.selectedTestIds,
          ...selectedTests,
        ]);
        // this.testsData = selectedTests;
      } else {
        this.removeAll();
        this.panelsTestsData.selectedCoriellPanels =
          this.panelsTestsData.selectedCoriellPanels.filter(
            (x) => x.case_type != "WOUND"
          );
      }
      // this.$emit("updateTestPanles", this.panelsTestsData);
    },
    getUniquIds(ids) {
      return Array.from(new Set(ids));
    },
    removeAll() {
      let woundPanelTests = [].concat.apply(
        [],
        this.WoundPanels.map((e) => e.tests)
      );
      this.panelsTestsData.selectedTestIds =
        this.panelsTestsData.selectedTestIds.filter(
          (x) => woundPanelTests.indexOf(x) == -1
        );
    },
    setSelectedCoriellPanels() {
      this.selectedWoundPanels = [];

      let selectedWoundPanelValue =
        this.panelsTestsData.selectedCoriellPanels.find(
          (e) => e.case_type === "WOUND"
        );

      if (selectedWoundPanelValue) {
        selectedWoundPanelValue.test_panels.forEach((panel) => {
          let selectedWoundPanel = this.WoundPanels.find(
            (x) => x.code == panel
          );
          if (selectedWoundPanel) {
            this.selectedWoundPanels.push(selectedWoundPanel);
          }
        });
      }
    },

    checkConflictExist() {
      let panel = this.selectedWoundPanels.find((e) => {
        let otherPanels = this.selectedWoundPanels
          .filter((f) => f.code != e.code)
          .map((f) => f.code);
        return this.arrayInterSection(otherPanels, e.parallel_panels).length > 0
          ? true
          : false;
      });
      if (panel) {
        this.$alert(
          "<strong>" +
            panel.parallel_panels.join(", ") +
            " and " +
            panel.code +
            " are not allowed to select at a time </strong>",
          "Warning",
          {
            dangerouslyUseHTMLString: true,
            type: "warning",
          }
        );

        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
  async mounted() {
    this.panelsTestsData = this.panelTestsData;
    this.selectedTestProfile = this.customProfileTest;

    this.loading = true;
    try {
      if(this.isFacilityUser){
        await this.$store.dispatch("testProfiles/fetchCaseTypeTestProfile", {
          get_all: true,
          case_type: "WOUND",
        });
      }
      this.TestProfiles =
        this.getCaseTypeTestProfiles && this.getCaseTypeTestProfiles.data
          ? this.getCaseTypeTestProfiles.data
          : [];
      await this.$store.dispatch("cases/fetchCoriellTestsOfPanel", {
        lab_id: this.getUserPrimaryLabId,
        case_type: "WOUND",
      });
      this.WoundPanels = this.getCorriellTestPanels && this.getCorriellTestPanels.data
        ? this.getCorriellTestPanels.data.filter(panel => panel.code && panel.code == 'WOUND')
        : [];
      this.setSelectedCoriellPanels();
      // eslint-disable-next-line no-empty
      this.selectedCoriellPanels = JSON.parse(
        JSON.stringify(this.panelsTestsData.selectedCoriellPanels)
      );
      this.loading = false;
    } catch (err) {
      this.loading = false;
      console.log(err);
    }
  },
};
</script>
